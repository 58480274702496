import React, { useState, useRef, useEffect,  } from 'react';
import { getDatabase, ref, set, push, remove, get, } from "firebase/database";
import { Link } from "react-router-dom"; 
import Swal from "sweetalert2";

import app from "../../firebaseConfig";


function Start() {
  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [inputValue3, setInputValue3] = useState("");
  const [role, setRole] = useState('true');
  const [dasboard, setDasboard] = useState('true');
  const [selesai, setSelesai] = useState(false);


  // DARI PAPAN SCORE
  const [inputNilaiAKA, setInputNilaiAKA] = useState(0)
  const [inputNilaiAO, setInputNilaiAO] = useState(0)
  const [dataNilaiAKA, setDataNilaiAKA] = useState([]);
  const [dataNilaiAO, setDataNilaiAO] = useState([]);


  //DATA ATLET DARI FORM INPUT NAMA DAN KATA YANG DIMAINKAN
  const dataAtlet = {
    'nama': inputValue1,
    'kata': inputValue2,
    'kontingen': inputValue3
  }


  // SIMPAN DATA ATLET KE LOCAL STORAGE
  const dataAtletAO = ()=> localStorage.setItem('AO', JSON.stringify(dataAtlet));
  const dataAtletAKA = ()=> localStorage.setItem('AKA', JSON.stringify(dataAtlet));
  const nilaiCollection = {
    'aka': inputNilaiAKA,
    'ao': inputNilaiAO
  }
  const nilai = ()=> {
    localStorage.setItem('nilai', JSON.stringify(nilaiCollection));
  }

  const [dataAkaState, setDataAkaState] = useState([]);
  const [dataAoState, setDataAoState] = useState([]);

  // BUAT DATA SLUG KE FIREBASE -> SEBELUMNYA DI COMPONENT COUNTDOWN.JS
  const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const slugAka = `${dataAtlet.nama}ID=${makeid(10)}`;
  const slugAo = `${dataAtlet.nama}ID=${makeid(10)}`;

  const saveDataSlugAka = async () => {
    setDataAkaState(dataAtlet)
      const db = getDatabase(app);
      const newDocRef = push(ref(db, "score/tatami1/slugAka"));
      set(newDocRef, {
        slug: slugAka
        }).then( () => {
          setInputValue1("");
          setInputValue2("");
          setInputValue3("");
        }).catch((error) => {
          alert("error: ", error.message);
        })
    }

     const saveDataSlugAo = async () => {
      setDataAoState(dataAtlet)
      const db = getDatabase(app);
      const newDocRef = push(ref(db, "score/tatami1/slugAo"));
      set(newDocRef, {
        slug: slugAo
        }).then( () => {
          setInputValue1("");
          setInputValue2("");
          setInputValue3("");
        }).catch((error) => {
          alert("error: ", error.message);
        })
    }

    const deleteSlug = async (slugId) => {
      const db = getDatabase(app);
      const dbRefAka = ref(db, "score/tatami1/slugAka");
      const dbRefAo = ref(db, "score/tatami1/slugAo");
      await remove(dbRefAka);
      await remove(dbRefAo);
    }


  // FETCH DATA SLUG
  const [dataSlugAka, setDataSlugAka] = useState([]);
  const [dataSlugAo, setDataSlugAo] = useState([]);

  const fetchDataSlugAka = async () => {
    const db = getDatabase(app);
    const dbRef = ref(db, "score/tatami1/slugAka"); 
    const snapshot = await get(dbRef);
    if(snapshot.exists()) setDataSlugAka(Object.values(snapshot.val()));   
  }
  const fetchDataSlugAo = async () => {
    const db = getDatabase(app);
    const dbRef = ref(db, "score/tatami1/slugAo"); 
    const snapshot = await get(dbRef);
    if(snapshot.exists()) setDataSlugAo(Object.values(snapshot.val()));   
  }


    // DARI PAPAN SCORE
    const fetchDataAKA = async () => {
          fetchDataSlugAka();
          if (dataSlugAka.length === 0) {
            Swal.fire("Nilai atlet AKA masih kosong!");   
          } else {
            const db = getDatabase(app);
            const dbRef = ref(db, `score/tatami1/atlet=${dataSlugAka[0].slug}`)
            const snapshot = await get(dbRef);
            if(snapshot.exists()) {
              localStorage.setItem('Nilai AKA', JSON.stringify(Object.values(snapshot.val())));
              const nilaiAKA = JSON.parse(localStorage.getItem('Nilai AKA'));
              setDataNilaiAKA(nilaiAKA); 
            } else {
              Swal.fire("Nilai atlet AKA masih kosong!");
            }
          }        
      }

  const fetchDataAO = async () => {
      fetchDataSlugAo();
      if (dataSlugAo.length === 0) {
        Swal.fire("Nilai atlet AO masih kosong!");
      } else {        
        const db = getDatabase(app);
        const dbRef = ref(db, `score/tatami1/atlet=${dataSlugAo[0].slug}`); 
        const snapshot = await get(dbRef);
        if(snapshot.exists()) {
          localStorage.setItem('Nilai AO', JSON.stringify(Object.values(snapshot.val())));
          const nilaiAO = JSON.parse(localStorage.getItem('Nilai AO'));
          setDataNilaiAO(nilaiAO); 
        } else {
          Swal.fire("Nilai atlet AO masih kosong!");
        }
      }
    
  }


  const [jumlahJuri, setJumlahJuri] = useState('');
  const [juriDanPertandingan, setJuriDanPertandingan] = useState('true');


  const kalkulasiNilaiAKA = (data) => {
      if(data.length === jumlahJuri){
          const myNums = data.map((item, index) => {
                let res = []
                res.push(item.skorAtlet)
                return res
            })
          let sorted = myNums.slice().sort(function(a, b) {
            return a - b;
          });
          let sum = 0;
          for (let i = 1; i < sorted.length-1; i++ ) {
            sum += parseFloat(sorted[i]);
          }
          setInputNilaiAKA(sum.toFixed(2));
      }
    }

    const kalkulasiNilaiAO = (data) => {
      if(data.length === jumlahJuri){        
          const myNums = data.map((item, index) => {
              let res = []
              res.push(item.skorAtlet)
              return res
          })          
          let sorted = myNums.slice().sort(function(a, b) {
            return a - b;
          });
          let sum = 0;
          for (let i = 1; i < sorted.length-1; i++ ) {
            sum += parseFloat(sorted[i]);
          }
          setInputNilaiAO(sum.toFixed(2));
      }
    }


    const fetchData = () => {
      fetchDataAKA();
      fetchDataAO();
      kalkulasiNilaiAKA(dataNilaiAKA);
      kalkulasiNilaiAO(dataNilaiAO);
      if(inputNilaiAO && inputNilaiAKA){
        nilai();
      }
    }


      const selesaiFunction = () => {
              deleteSlug(); 
              setDasboard(true); 
              setRole('false'); 
              setDataNilaiAKA([]); 
              setDataNilaiAO([]); 
              setInputNilaiAKA(0); 
              setInputNilaiAO(0);
              setDataSlugAka([]);
              setDataSlugAo([]);
              localStorage.removeItem('AO');
              localStorage.removeItem('firebase:previous_websocket_failure');
              localStorage.removeItem('dasboard');
              localStorage.removeItem('nilai');
              localStorage.removeItem('AKA');
              localStorage.removeItem('role');
              localStorage.removeItem('dataSlugAka');
              localStorage.removeItem('dataSlugAo');
              localStorage.removeItem('Nilai AO');
              localStorage.removeItem('Nilai AKA');
              setSelesai(false);
              nilai();
              window.location.reload();
      }

      const belumSelesaiFunction = () => {
        if(inputNilaiAO && inputNilaiAKA){
          nilai();
        }       
        nilai();
        setSelesai(true);
      }


  // DARI COUNTDOWN
  //=============================================================================================
  const [sudah, setSudah] = useState(false);
  const Ref = useRef(null);
  const [timer, setTimer] = useState("00:00");
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,      
      minutes,
      seconds,
    };
  };
  const startTimer = (e) => {
    let { total, minutes, seconds } =
      getTimeRemaining(e);
    if (total >= 0) {
      setTimer(       
        (minutes > 9 ? minutes : "0" + minutes) + ":" + (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };
  const clearTimer = (e) => {
    setTimer('05:00');
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };
  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 300);
    return deadline;
  };
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };
  //=============================================================================================

  useEffect(()=>{
    const juriDanPertandinganTemp = JSON.parse(localStorage.getItem('juriDanPertandingan'));
    const dasboardTemp = JSON.parse(localStorage.getItem('dasboard'));
    const roleTemp = JSON.parse(localStorage.getItem('role'));
    const jumlahJuriTemp = JSON.parse(localStorage.getItem('jumlahJuri'));
    if(juriDanPertandinganTemp) setJuriDanPertandingan(juriDanPertandinganTemp);
    if(dasboardTemp) setDasboard(dasboardTemp);
    if(roleTemp) setRole(roleTemp);
    if(jumlahJuriTemp) setJumlahJuri(jumlahJuriTemp);


    const dataAtletAO = JSON.parse(localStorage.getItem('AO'));
    if(dataAtletAO)setDataAoState(dataAtletAO);
    const dataAtletAKA = JSON.parse(localStorage.getItem('AKA'));
    if(dataAtletAKA)setDataAkaState(dataAtletAKA);
  },[])


  const juriDanPertandinganFunc = () => {
    localStorage.setItem('juriDanPertandingan', JSON.stringify('false'));
    const juriSet = JSON.parse(localStorage.getItem('juriDanPertandingan'));
    setJuriDanPertandingan(juriSet);
  }

  const dasboardFunc = () => {
    localStorage.setItem('dasboard', JSON.stringify('false'));
    const dasboardSet = JSON.parse(localStorage.getItem('dasboard'));
    setDasboard(dasboardSet);
  }

  const roleFunc = () => {
    localStorage.setItem('role', JSON.stringify('false'));
    const roleSet = JSON.parse(localStorage.getItem('role'));
    setRole(roleSet);
  }

  const jumlahJuriFunc = (j) => {
    localStorage.setItem('jumlahJuri', JSON.stringify(j));
    const jumlahJuriSet = JSON.parse(localStorage.getItem('jumlahJuri'));
    setJumlahJuri(jumlahJuriSet);
  }

  return (
    juriDanPertandingan === 'true'?
    <div
            style={{
              backgroundImage: `url(${require('./../../images/bk.jpg')})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              justifyContent: 'center',
              alignContent: 'center',
              width: '100vw',
              height: '100vh'
            }}>
            <h1 style={{
              color: "#FFF",
              fontSize: 40,
              marginLeft: 100,
              marginRight: 100,
              paddingTop: 10,
              fontFamily: `'Anton', 'sans-serif'`,
              fontWeight: 400,
              fontStyle: 'normal',
            }}>BUPATI CUP 3 LOMBOK TENGAH</h1>

            <button 
            style={{
              borderRadius: 30,
              height: 48,
              width: 'auto',
              marginTop: 15,
              border: 0,
              padding: 15,
              cursor: 'pointer'
            }}
            onClick={()=>{setJuriDanPertandingan('false'); setJumlahJuri(5); jumlahJuriFunc(5); juriDanPertandinganFunc();}}
            className='button1' >PERTANDINGAN 5 JURI</button>

            <button 
            style={{
              borderRadius: 30,
              height: 48,
              width: 'auto',
              marginTop: 15,
              border: 0,
              padding: 15,
              cursor: 'pointer',
              margin: 20
            }}
            onClick={()=>{setJuriDanPertandingan('false'); setJumlahJuri(7); jumlahJuriFunc(7); juriDanPertandinganFunc();}}
            className='button1' >PERTANDINGAN 7 JURI</button>

            <br/>


            <button 
            style={{
              backgroundColor: '#fff',
              paddingLeft: 20,
              paddingRight: 20,
              border: 0,
              borderRadius: 30,
              padding: 10
            }}
            onClick={()=>{setJuriDanPertandingan('true'); localStorage.removeItem('juriDanPertandingan'); localStorage.removeItem('jumlahJuri');}}
            className='button1' >
              <Link to='/users'
                style={{fontSize: 22}}
              >

                PROFILE

              </Link>
            </button>



    </div>
          :
    dasboard === 'true'?
    role === 'true'?
    
    <div>

        <div style={{ 
          backgroundImage: `url(${require('./../../images/board.png')})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '100vh',
          justifyContent: 'center',
          alignContent: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}>
          <div
            style={{

              backgroundImage: `url(${require('./../../images/bk.jpg')})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              justifyContent: 'center',
              alignContent: 'center',
              width: '100vw',
              height: '100vh'
            }}

          >
            <h1>BUPATI CUP 3 LOMBOK TENGAH</h1>
          <button 
            style={{
              backgroundColor: '#fff',
              paddingLeft: 20,
              paddingRight: 20,
              border: 0,
              borderRadius: 30,
              padding: 10
            }}
            onClick={()=>{setJuriDanPertandingan('true'); localStorage.removeItem('juriDanPertandingan'); localStorage.removeItem('jumlahJuri');}}
            className='button1' >
              <Link to='/users'
                style={{fontSize: 22}}
              >

                PROFILE

              </Link>
            </button>
          </div>

          <div
            style={{

              justifyContent: 'center',
              alignContent: 'center',
              width: '100vw',
              height: '100vh'
            }}

          >

            <h2 style={{
              marginTop: 120,
              fontSize: 40,
              paddingBottom: 30
            }}>PERTANDINGAN SELANJUTNYA!</h2>

            <text
            style={{
              fontStyle: "bold",
              fontSize: 20,
              margin: 15,
            }}
            >NAMA ATLET [AKA]</text> <br />

            <input type="text"
            value={inputValue1} 
            style={{
              textColor: "#000",
              borderRadius: 30,
              height: 32,
              width: "80%",
              padding: 8,
              paddingLeft: 15,
              fontSize: 18,
              marginTop: 10,
              marginBottom: 15,
              borderColor:"aquamarine"
            }}
            onChange={(e) => setInputValue1(e.target.value)}/> <br />


            <text
            style={{
              fontStyle: "bold",
              fontSize: 20,
              margin: 15,
            }}
            >KONTINGEN [AKA]</text> <br />

            <input type="text"
            value={inputValue3} 
            style={{
              textColor: "#000",
              borderRadius: 30,
              height: 32,
              width: "80%",
              padding: 8,
              paddingLeft: 15,
              fontSize: 18,
              marginTop: 10,
              marginBottom: 15,
              borderColor:"aquamarine"
            }}
            onChange={(e) => setInputValue3(e.target.value)}/> <br />

            <text
            style={{
              fontStyle: "bold",
              fontSize: 20,
              margin: 15,
            }}
            >KATA YANG DIMAINKAN</text> <br />

            <input type='text' 
            value={inputValue2} 
            style={{
              textColor: "#000",
              borderRadius: 30,
              height: 32,
              width: "80%",
              padding: 8,
              paddingLeft: 15,
              fontSize: 18,
              marginTop: 10,
              marginBottom: 15,
              borderColor:"aquamarine"
            }}
            onChange={(e) => setInputValue2(e.target.value)}/> <br />



            <button 
            style={{
              borderRadius: 30,
              height: 48,
              width: '80%',
              marginTop: 15,
              border: 0,
              padding: 15,
              cursor: 'pointer'
            }}
            onClick={()=>{dataAtletAKA(); setRole('false'); roleFunc(); saveDataSlugAka(); }}
            className='button1' >MAINKAN AKA!</button>


          </div>
        

        </div>



    </div>

    :

    <div>
        
        <div style={{ 
          backgroundImage: `url(${require('./../../images/board.png')})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '100vh',
          justifyContent: 'center',
          alignContent: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}>




          <div
            style={{

              justifyContent: 'center',
              alignContent: 'center',
              width: '100vw',
              height: '100vh',        }}

          >

            <h2 style={{
              marginTop: 120,
              fontSize: 40,
              paddingBottom: 30
            }}>PERTANDINGAN SELANJUTNYA!</h2>

            <text
            style={{
              fontStyle: "bold",
              fontSize: 20,
              margin: 15,
            }}
            >NAMA ATLET [AO]</text> <br />

            <input type="text"
            value={inputValue1} 
            style={{
              textColor: "#000",
              borderRadius: 30,
              height: 32,
              width: "80%",
              padding: 8,
              paddingLeft: 15,
              fontSize: 18,
              marginTop: 10,
              marginBottom: 15,
              borderColor:"aquamarine"
            }}
            onChange={(e) => setInputValue1(e.target.value)}/> <br />

            <text
            style={{
              fontStyle: "bold",
              fontSize: 20,
              margin: 15,
            }}
            >KONTINGEN [AO]</text> <br />

            <input type="text"
            value={inputValue3} 
            style={{
              textColor: "#000",
              borderRadius: 30,
              height: 32,
              width: "80%",
              padding: 8,
              paddingLeft: 15,
              fontSize: 18,
              marginTop: 10,
              marginBottom: 15,
              borderColor:"aquamarine"
            }}
            onChange={(e) => setInputValue3(e.target.value)}/> <br />

            <text
            style={{
              fontStyle: "bold",
              fontSize: 20,
              margin: 15,
            }}
            >KATA YANG DIMAINKAN</text> <br />

            <input type='text' 
            value={inputValue2} 
            style={{
              textColor: "#000",
              borderRadius: 30,
              height: 32,
              width: "80%",
              padding: 8,
              paddingLeft: 15,
              fontSize: 18,
              marginTop: 10,
              marginBottom: 15,
              borderColor:"aquamarine"
            }}
            onChange={(e) => setInputValue2(e.target.value)}/> <br />



            <button 
            style={{
              borderRadius: 30,
              height: 48,
              width: '80%',
              marginTop: 15,
              border: 0,
              padding: 15,
              cursor: 'pointer'
            }}
            onClick={()=>{dataAtletAO(); setDasboard('false'); dasboardFunc(); saveDataSlugAo(); }}
            className='button1' >MAINKAN AO!</button>


          </div>

          <div
            style={{

              backgroundImage: `url(${require('./../../images/bk.jpg')})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              justifyContent: 'center',
              alignContent: 'center',
              width: '100vw',
              height: '100vh'
            }}

          >
            <h1>BUPATI CUP 3 LOMBOK TENGAH</h1>
            <button 
            style={{
              backgroundColor: '#fff',
              paddingLeft: 20,
              paddingRight: 20,
              border: 0,
              borderRadius: 30,
              padding: 10
            }}
            onClick={()=>{setJuriDanPertandingan('true'); localStorage.removeItem('juriDanPertandingan'); localStorage.removeItem('jumlahJuri');}}
            className='button1' >
              <Link to='/users'
                style={{fontSize: 22}}
              >

                PROFILE

              </Link>
            </button>

          </div>
            
        </div>


    </div>


    


    :


    <div style={{ 
      backgroundImage: `url(${require('./../../images/board.png')})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '100vh',
      justifyContent: 'center',
      alignContent: 'center',
      alignItem: 'center',
    }}>


      <h1 style={{
        color: "#FFF",
        fontSize: 40,
        marginLeft: 100,
        marginRight: 100,
        paddingTop: 10,
        fontFamily: `'Anton', 'sans-serif'`,
        fontWeight: 400,
        fontStyle: 'normal',
      }}>DASBOARD ADMIN NATIONAL OPEN KARATE CHAMPIONSHIP BUPATI CUP 3 2024</h1>        
       

      <div style={{
        color: '#FFF',
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'center',
        alignContent: 'center'
      }}>
        <div style={{ flex: 1}}>
          <h1 style={{fontSize: 50}}>AO</h1>
          <h2>{dataAoState.length === 0 ? <text>-</text> : dataAoState.nama}</h2>
          <h2>{dataAoState.length === 0 ? <text>-</text> : dataAoState.kata}</h2>
          <h1 style={{fontSize: 60}}>{inputNilaiAO}</h1>
        </div>


        

      



          {
          <div>
      
      <h1 style={{ color: "#FFF", fontSize: 36 }}>
        {"SISA WAKTU"}
      </h1>
      {
        sudah?
        <button 
        style={{
              borderRadius: 15,
              height: 32,
              width: 72,
              border: 0,
              fontStyle: 'Trebuchet MS',
              fontWeight: 'bolder',
              backgroundColor: '#000',
              color: '#FFF',
              opacity: 0.7,
              cursor: 'pointer',

            }}
            onClick={()=> {clearTimer(); 
              setSudah(false); 
              localStorage.removeItem('time');}}
            >
              RESET
        </button> 


        :

        
        <button 
        style={{
              borderRadius: 15,
              height: 32,
              width: 72,
              border: 0,
              fontStyle: 'Trebuchet MS',
              fontWeight: 'bolder',
              backgroundColor: '#000',
              color: '#FFF',
              opacity: 0.7,
              cursor: 'pointer'
            }}
        onClick={()=>{onClickReset(); 
          setSudah(true); 
          localStorage.setItem('time', JSON.stringify('start'));
        }}>MULAI</button> 
      }


      <h1 style={{fontSize: 46, color: '#FFF'}}>{timer}</h1>
      
      
    </div>
        }



        <div style={{flex: 1}}>
          <h1 style={{fontSize: 50}}>AKA</h1>
          <h2>{dataAkaState.length === 0 ? <text>-</text> : dataAkaState.nama}</h2>
          <h2>{dataAkaState.length === 0 ? <text>-</text> : dataAkaState.kata}</h2>
          <h1 style={{fontSize: 60}}>{inputNilaiAKA}</h1>

        </div>

        


      </div>

      
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >

              <div style={{
                  listStyle: "none",
                  MozColumnCount: 5,
                  MozColumnGap: '20px',
                  WebkitColumnCount: 5,
                  WebkitColumnGap: '20px',
                  columnCount: 5,
                  columnGap: '20px',
                  marginBottom: 0,
                  marginRight: 180,
                  color: '#FFF',
                  justifyContent: 'left',
                  }}>
                  {
                      dataNilaiAO.map((item, index)=>(
                        <text key={index} style={{fontSize: 16}}> 
                          {item.juri.substring(0,5)} <br/> Skor: {item.skorAtlet} <br/>
                        </text>
                      ))
                  }

              </div>

              <div style={{
                  MozColumnCount: 5,
                  MozColumnGap: '20px',
                  WebkitColumnCount: 5,
                  WebkitColumnGap: '20px',
                  columnCount: 5,
                  columnGap: '20px',
                  marginBottom: 0,
                  color: '#FFF',
                  justifyContent: 'left',
                  }}>
                  {

                      dataNilaiAKA.map((item, index)=>(
                        <text key={index} style={{fontSize: 16}}> 
                            {item.juri.substring(0,5)} <br/> Skor: {item.skorAtlet} <br/>
                        </text>

                      ))
                  }
               </div>

            </div>

            <button 
          style={{
            borderRadius: 15,
            height: 48,
            width: "15%",
            border: 0,
            color: '#FFF',
            cursor: 'pointer'
          }}
          className='button1'
          onClick={fetchData}
          > UPDATE SKOR </button> <br/>


      <button 
          style={{
            borderRadius: 15,
            marginTop: 15,
            height: 48,
            width: "15%",
            border: 0,
            color: '#FFF',
            cursor: 'pointer'
          }}
          className='button1'
          onClick={
            selesai ? selesaiFunction : belumSelesaiFunction
          }
          > {selesai? 'PERTANDINGAN SELESAI' : 'RESET SKOR'} </button>



    </div>
  )
}

export default Start;